import React from "react";
import './coming.css'
const News=()=>{
    return(
   

<div className='coming'>
   <h1>NEWS-PAGE</h1>
   <p>coming soon !</p>

</div>


    
  


    )
}

export default News;